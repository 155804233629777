<template>
  <div class="email-app-details bg-white">

    <div class="email-detail-header">
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ $t('Adicionar imóvel de interesse') }}
        </h4>
      </div>
    </div>

    <vue-perfect-scrollbar
      ref="scrollContentPage"
      class="email-scroll-area scroll-area pr-0 pl-0"
      :settings="configSettingsScroll"
    >

      <list-imoveis
        :tabs-show="['favorites', 'list', 'myHUB']"
        :add-new="addNewSW031"
        :scroll="$refs.scrollContentPage"
      />

    </vue-perfect-scrollbar>

  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import ListImoveis from '@/views/modules/components/modalListingSearch/index.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    VuePerfectScrollbar,
    ListImoveis,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    saveFormSW031: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    async addNewSW031(pid) {
      if ((pid !== null) && (pid !== '')) {
        await this.saveFormSW031(pid)
      } else {
        this.showMsgErrorRequest(new Error(this.$t('Indique um ZMID')))
      }
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
